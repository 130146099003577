<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="BoxHeight bbox plr15">
      <!-- 账号信息 -->
      <el-form
        ref="InfoFormRef"
        :model="InfoForm"
        label-width="115px"
        label-position="left"
        class="edit edit2"
        :disabled="isdisabled2"
      >
        <div class="text333 f15 fw700 pt20 pb20">
          {{ $t("my.accountInfo") }}
        </div>
        <el-form-item :label="$t('my.account')">
          <el-input v-model="InfoForm.phone" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('my.pwd')" v-if="isdisabled2 == false">
          <el-input
            v-model="InfoForm.oldPassword"
            type="password"
            show-password
            :placeholder="$t('my.checkPwd')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('my.newPwd')" v-if="isdisabled2 == false">
          <el-input
            v-model="InfoForm.newPassword"
            type="password"
            show-password
            :placeholder="$t('my.min')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('my.confirmPwd')" v-if="isdisabled2 == false">
          <el-input
            v-model="InfoForm.checkPasd"
            type="password"
            show-password
            :placeholder="$t('my.min')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="textc">
        <el-button type="primary" @click="editPwd" class="wp80 f16 Btn">{{
          btnValue2
        }}</el-button>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>
<script>
import { Toast } from "vant";
import Header from "../../components/commonHead.vue";
import Footer from "../../components/footer.vue";
var tips1 = "修改密码";
var tips2 = "确认修改";
var tips3 = "请输入原密码";
var tips4 = "请输入新密码";
var tips5 = "请再次输入密码";
var tips6 = "两次输入密码不一致";
var tips7 = "修改成功";
var tips8 = "失败原因";
var tips9 = "发送成功";

export default {
  components: { Header, Footer },
  data() {
    return {
      isdisabled: true,
      isdisabled2: true,
      btnValue2: tips1,
      InfoForm: {
        phone: "",
        code: "",
        password: "123456",
        oldPassword: "",
        newPassword: "",
        checkPasd: "",
      },
    };
  },
  methods: {
    // 修改密码
    editPwd() {
      if (this.btnValue2 == tips1) {
        this.isdisabled2 = false;
        this.btnValue2 = tips2;
      } else {
        if (!this.InfoForm.oldPassword) {
          Toast(tips3);
          return;
        }
        if (!this.InfoForm.newPassword) {
          Toast(tips4);
          return;
        }
        if (!this.InfoForm.checkPasd) {
          Toast(tips5);
          return;
        }
        if (this.InfoForm.checkPasd != this.InfoForm.newPassword) {
          Toast(tips6);
          return;
        }
        var that = this;
        var sendData = {};
        sendData.phone = this.InfoForm.phone;
        sendData.oldPassword = this.InfoForm.oldPassword;
        sendData.password = this.InfoForm.newPassword;
        this.$http.post("member/updatePassword", sendData).then(function (res) {
          if (200 == res.data.code) {
            Toast.success(tips7);
            localStorage.removeItem("member");
            localStorage.removeItem("memberCode");
            that.$router.push("/login");
          } else {
            Toast.fail(tips8 + res.data.message);
          }
          that.btnValue2 = tips1;
          that.isdisabled2 = true;
        });
      }
    },
    handleCodeClick() {
      var sendData = {};
      sendData.phone = this.InfoForm.phone;
      sendData.type = "changePwd";
      this.$http.post("authCode/sendMsg", sendData).then(function (res) {
        if (200 == res.data.code) {
          Toast.success(tips9);
        }
      });
    },
    changeLanguage() {
      if (this.$store.getters.language == "zh") {
        tips1 = "修改密码";
        tips2 = "确认修改";
        tips3 = "请输入原密码";
        tips4 = "请输入新密码";
        tips5 = "请再次输入密码";
        tips6 = "两次输入密码不一致";
        tips7 = "修改成功";
        tips8 = "失败原因";
        tips9 = "发送成功";
      }
      if (this.$store.getters.language == "en") {
        tips1 = "Change Password";
        tips2 = "Confirm the change";
        tips3 = "Please enter the original password";
        tips4 = "Please enter a new password";
        tips5 = "Please enter your password again";
        tips6 = "The two passwords do not match";
        tips7 = "modify successfully";
        tips8 = "failure reasons";
        tips9 = "send successfully";
      }
      if (this.$store.getters.language == "ja") {
        tips1 = "パスワードを修正する";
        tips2 = "修正を確認する";
        tips3 = "元のパスワードを入力してください";
        tips4 = "新しいパスワードを入力してください";
        tips5 = "パスワードを再度入力してください";
        tips6 = "2回入力したパスワードが一致しない";
        tips7 = "修正に成功した";
        tips8 = "失敗の原因";
        tips9 = "送信成功";
      }
      if (this.isdisabled2 == false) {
        this.btnValue2 = tips2;
      } else {
        this.btnValue2 = tips1;
      }
    },
  },
  created() {
    this.InfoForm = JSON.parse(localStorage.getItem("member"));
    this.changeLanguage();
  },
  mounted() {
    this.changeLanguage();
  },
  updated() {
    this.changeLanguage();
  },
};
</script>
<style scoped>
.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}
.Btn {
  margin: 40px auto;
}
.edit >>> .el-input__inner {
  padding-right: 10px;
}
.edit2 >>> .el-input__inner {
  padding-right: 25px;
}

.edit >>> .el-form-item__label {
  line-height: 16px;
  color: #333;
  display: flex;
  align-items: center;
}

.edit2 >>> .el-input__suffix {
  top: 3px;
  right: -12px;
}
.edit2 >>> .code .el-input__suffix {
  top: 5px;
  right: 5px;
}
.BoxHeight
  >>> .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  content: " ";
  width: 0px;
  margin-right: 0px;
}
</style>



